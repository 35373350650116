html,body{
    margin: 0;
    padding: 0;
    height:100%;
}
#root,#main{
    height:100%;
}
#main{
    background: rgb(236, 236, 236);
    display: flex;
    align-items: center;
    justify-content: center;
}