.my-collection{
    width: 100%;
}
.my-collection .main-con{
    display:flex;
    flex-flow: row wrap;
    padding:20px;
    &>div{
        width:200px;
        height:260px;
        margin:5px;
        position: relative;
    }    
    .myCollection{
        padding:0 10px
     }
     .plusCon{
         display: flex;
         flex-flow: column wrap;
         label{
            display:flex;
            flex: 1;
         }
     }
     .h53 {
         display:flex;
         height:63px;
     }
     .button {
        width: 100%;
        height: 100%;
        background:#ccc;
      }      
      .img{
        width: 98px;
        height: 98px;
        background: #ccc;
        margin: 1px;
        border-radius: 3px;
     }
     .flexDiv{
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        cursor: pointer;
        button{
            position: absolute;
            right:10px;
            top:10px;
            z-index:1;
        }        
     }
}
@media(max-width:600px){
    .my-collection .main-con{
        justify-content: center;
    }
}