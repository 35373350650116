html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#root,
#main {
  height: 100%;
}
#main {
  background: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.toast-notification {
    position: fixed;
    top: 20px;
    left: 30%;
    right: 30%;
    display: flex;
    flex-direction: column;
    z-index: 9999;
}

.toast-notice-wrapper.notice-enter {
    opacity: 0.01;
    -webkit-transform: scale(0);
            transform: scale(0);
}

.toast-notice-wrapper.notice-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    /*transition: all 300ms ease-out;*/
}

.toast-notice-wrapper.notice-exit {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.toast-notice-wrapper.notice-exit-active {
    opacity: 0.01;
    -webkit-transform: translateY(-40%);
            transform: translateY(-40%);
    /*transition: all 300ms ease-out;*/
}

.toast-notice {
    background: #FFFFFF;
    padding: 16px 32px;
    margin: 8px auto;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, .1);
    border-radius: 6px;
    color: #454545;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.toast-notice>span {
    margin-left: 6px;
    line-height: 100%;
}

.toast-notice>svg {
    font-size: 18px;
}

.toast-notice.info>svg {
    color: #1890FF;
}

.toast-notice.success>svg {
    color: #52C41A;
}

.toast-notice.warning>svg {
    color: #FAAD14;
}

.toast-notice.error>svg {
    color: #F74A53;
}

.toast-notice.loading>svg {
    color: #1890FF;
    -webkit-animation: rotating 1s linear infinite;
            animation: rotating 1s linear infinite;

}

@-webkit-keyframes rotating {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotating {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.chatbox-con {
  display: flex;
  min-width: 800px;
  height: 400px;
  flex-direction: column;
}
.chatbox-con .date-time {
  text-align: center;
  color: #a0a09d;
  line-height: 24px;
  margin: 13px;
}
.chatbox-con .guest,
.chatbox-con .me {
  position: relative;
  border-radius: 5px;
  align-items: flex-start;
  margin: 20px 5px;
  word-break: break-all;
  padding: 10px 20px;
  max-width: 735px;
  line-height: 18px;
  color: #000;
}
.chatbox-con .guest {
  background: #dcd8d8;
  align-self: flex-start;
  margin-left: 30px;
}
.chatbox-con .me {
  background: #39c2ea;
  align-self: flex-end;
  margin-right: 30px;
}
.chatbox-con .guest .avatar {
  position: absolute;
  left: -45px;
  bottom: -35px;
  /* width: 50px; */
  text-align: center;
}
.chatbox-con .guest .avatar .avatar-icon {
  width: 40px;
  height: 40px;
  display: block;
}
.chatbox-con .guest .avatar span {
  color: #9e9393;
  width: 100px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: left;
  padding-right: 5px;
}
.chatbox-con .guest .arrow:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -14px;
  bottom: 1px;
  border: 8px solid #dcd8d8;
  border-color: transparent #dcd8d8 transparent  transparent ;
}
.chatbox-con .me .avatar {
  position: absolute;
  right: -45px;
  bottom: -35px;
  /* width: 50px; */
  text-align: center;
}
.chatbox-con .me .avatar .avatar-icon {
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: 0px;
  top: -44px;
}
.chatbox-con .me .avatar span {
  color: #9e9393;
  width: 100px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: right;
  padding-right: 5px;
}
.chatbox-con .me .arrow:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: -14px;
  bottom: 1px;
  border: 8px solid #39c2ea;
  border-color: transparent transparent transparent #39c2ea;
}
.input-con {
  width: 100%;
  flex: 1 1;
  display: flex;
}
.input {
  flex: 1 1;
  display: flex;
  border: none;
  resize: unset;
  font-family: none;
}
.input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.flex {
  display: flex;
}
.triangle {
  width: 0;
  height: 0;
  display: inline-block;
  border-style: solid;
  border-width: 10px 10px 10px 10px;
  border-color: #f44336 transparent #f44336 #f44336;
}
.wrap {
  flex-wrap: wrap;
}
.fullWidth {
  width: 100%;
}
.marTB10 {
  margin: 5px 10px 5px 0;
}
.marTB10 > div {
  max-width: 20%;
  min-width: 180px;
}
.mr10 {
  margin-right: 10px;
}
.textLeft {
  text-align: left;
}
.textLeft div {
  text-align: left;
}
.stars {
  padding: 15px 0;
}
.stars span {
  display: flex;
  justify-content: flex-start;
}
.twoLines {
  width: 100%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
@media (max-width: 768px) {
  .xs-per100 {
    width: 100%;
    margin: 5px 10px 5px 0;
    box-sizing: border-box;
  }
}
@media (min-width: 768px) {
  .sm-per33 {
    width: 33.3%;
    margin: 5px 10px 5px 0;
    box-sizing: border-box;
  }
}
@media (min-width: 960px) {
  .md-per10 {
    width: 10%;
    min-width: 180px;
    margin: 5px 10px 5px 0;
    box-sizing: border-box;
  }
}
.newbox,
.updatebox {
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 1;
  color: #fff;
  font-size: 0;
  width: 70px;
  display: inline-block;
  height: 20px;
  line-height: 20px;
}
.newbox span,
.updatebox span {
  height: 20px;
  font-size: 14px;
  width: 50px;
  padding-left: 10px;
  background: #f44336;
  vertical-align: middle;
  display: inline-block;
}
.newbox i,
.updatebox i {
  height: 20px;
  vertical-align: middle;
  display: inline-block;
}
.cross-newbox,
.cross-updatebox {
  position: relative;
  color: #fff;
  font-size: 0;
  width: 70px;
  display: inline-block;
  height: 20px;
  line-height: 20px;
}
.cross-newbox span,
.cross-updatebox span {
  height: 20px;
  font-size: 14px;
  width: 50px;
  color: #fff;
  padding-left: 10px;
  background: #f44336;
  vertical-align: middle;
  display: inline-block;
}
.cross-newbox i,
.cross-updatebox i {
  height: 20px;
  vertical-align: middle;
  display: inline-block;
}
.updatebox,
.cross-updatebox {
  width: 85px;
}
.updatebox span,
.cross-updatebox span {
  width: 65px;
  background: #2196f3;
}
.updatebox i,
.cross-updatebox i {
  border-color: #2196f3 transparent #2196f3 #2196f3;
}
.message {
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 1;
}
.one-list {
  display: flex;
  flex-direction: column;
  margin: 10px 20px 20px 0;
}
.one-list .w150 {
  width: 150px;
  height: 208px;
  position: relative;
  border: 1px #ccc solid;
  cursor: pointer;
  background-color: #fff;
}
.one-list .w150 img {
  width: 100%;
  height: 100%;
}
.one-list .w150-bottom {
  width: '150px';
  white-space: nowrap;
}
.crossWrap {
  width: 100%;
}
.crossPage {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.crossPage .leftPart {
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
}
.crossPage .leftPart .imgCon {
  width: 150px;
  height: 208px;
  cursor: pointer;
  background-color: #fff;
}
.crossPage .leftPart .imgCon img {
  width: 100%;
  height: 100%;
}
.crossPage .leftPart .textCon {
  padding-left: 20px;
  flex: 1 1;
}
.crossPage .rightPart {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.img-right {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.img-right p {
  margin: 0;
}
.img-right .mb10 {
  margin-bottom: 10px;
}
.loading-div {
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  position: fixed;
}
.loading-div .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  outline: none;
}
.search-list .search-item {
  cursor: pointer;
}

.mar5 {
  margin: 5px;
}
.pd10 {
  padding: 10px;
}
.text-center {
  text-align: center;
}
.book-wrap {
  width: 100%;
}
.book-wrap .tool-bars {
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.book-wrap .tool-bars .center-part {
  font-size: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 59px;
  max-width: 20%;
}
.book-wrap .tool-bars .right-part em {
  font-style: normal;
}
.book-wrap .tool-bars .right-part .option-box-inline em div:nth-child(2) {
  display: inline-block;
}
.book-wrap #bookContainer {
  margin: 0 auto;
  min-width: 100%;
  max-width: 85%;
  padding: 10px 0 50px;
  position: relative;
  z-index: 1;
}
@media print {
  .book-wrap #bookContainer {
    display: none;
  }
}
.book-wrap #book {
  padding: 20px;
  width: 90%;
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
}
.book-wrap #book .page_single {
  margin: 0 auto;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  border: 1px solid #dcdbdb;
}
.book-wrap #book .page_horizontal {
  width: 100%;
  display: flex;
  justify-content: space-around;
  -webkit-transform-origin: top left;
          transform-origin: top left;
}
.book-wrap #book .page_horizontal .react-pdf__Page {
  border: 1px solid #dcdbdb;
}
.book-wrap #bookView {
  width: 86%;
  margin: 0px auto;
  display: block;
  border: 1px solid #efeded;
}
#textLayer ::-moz-selection {
  background: #2196f3;
}
#textLayer ::selection {
  background: #2196f3;
}
.fontsize-wrap {
  padding: 40px;
  overflow: hidden;
}
#colorSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111;
}
.print-wrap {
  position: relative;
  display: inline-block;
  z-index: 9;
  font-style: normal;
}
.search-list .search-item {
  padding: 10px 0;
  display: flex;
  border-bottom: 1px #ccc solid;
  justify-content: space-between;
}
.star-wrap {
  padding: 20px 60px;
}
.star-wrap span {
  cursor: pointer;
}
.slider-wraper {
  width: 2px;
  height: 100%;
  position: absolute;
  top: -300px;
  z-index: 1;
}
.slider-wraper .rigth-slider > div {
  overflow: visible;
}
.page-btn {
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top: 10%;
  width: 4%;
  height: 55%;
  z-index: 2;
  display: flex;
  align-items: center;
  background: #eee;
}
.page-btn:hover {
  background: #ddd;
}
.page-btn svg {
  width: 100%;
  height: 60px;
}
.prev-btn {
  left: 12px;
}
.next-btn {
  right: 12px;
}
#loadingDiv .MuiDialog-paperWidthSm-255 {
  background: transparent!important;
  box-shadow: none!important;
}
.serchselect {
  margin: -1px;
  padding: 1px;
  background-color: yellow;
  border-radius: 4px;
}
@media print {
  @page {
    size: portrait;
    margin: 2mm;
  }
  #pdfPrint div {
    background: white;
    page-break-after: always;
  }
}
.option-box {
  position: relative;
}
.option {
  position: absolute;
  right: 10px;
  z-index: 9;
}
.option ul {
  overflow-y: auto;
  max-height: 255px;
}
.fab-wrap {
  display: flex;
  position: fixed;
  bottom: 50px;
  right: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.fab-wrap .option-btn {
  width: 36px;
  height: 36px;
  margin: 5px 0px;
}
.iconSmall {
  width: 22px;
  height: 22px;
}
.iconBig {
  width: 35px;
  height: 35px;
}

.my-collection {
  width: 100%;
}
.my-collection .main-con {
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
}
.my-collection .main-con > div {
  width: 200px;
  height: 260px;
  margin: 5px;
  position: relative;
}
.my-collection .main-con .myCollection {
  padding: 0 10px;
}
.my-collection .main-con .plusCon {
  display: flex;
  flex-flow: column wrap;
}
.my-collection .main-con .plusCon label {
  display: flex;
  flex: 1 1;
}
.my-collection .main-con .h53 {
  display: flex;
  height: 63px;
}
.my-collection .main-con .button {
  width: 100%;
  height: 100%;
  background: #ccc;
}
.my-collection .main-con .img {
  width: 98px;
  height: 98px;
  background: #ccc;
  margin: 1px;
  border-radius: 3px;
}
.my-collection .main-con .flexDiv {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  cursor: pointer;
}
.my-collection .main-con .flexDiv button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
@media (max-width: 600px) {
  .my-collection .main-con {
    justify-content: center;
  }
}

.one-line,
.two-line {
  width: 180px;
  position: relative;
  line-height: 20px;
  max-height: 40px;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
}
.two-line::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 5px;
  padding-left: 5px;
  background: -webkit-linear-gradient(left, transparent, #fff 0%);
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(60%, #fff));
  background: -webkit-linear-gradient(left, transparent, #fff 60%);
  background: linear-gradient(to right, transparent, #fff 60%);
}
.edit-mycollection {
  width: 101%;
  position: relative;
}
.edit-mycollection .dom-h2 {
  padding: 10px;
  padding-top: 13px;
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  border-bottom: 1px solid #dfd7d7;
  position: fixed;
  z-index: 5;
  top: 50px;
}
.edit-mycollection .dom-h2 .h2-title {
  display: inline-block;
  line-height: 33px;
}
.edit-mycollection .dom-h2 .search-option {
  display: flex;
  align-items: center;
  text-align: right;
}
.edit-mycollection .dom-h2 .search-option .search-box {
  flex: 1.5 1;
  display: flex;
  align-items: center;
  width: 250px;
  height: 33px;
}
.edit-mycollection .dom-h2 .search-option .search-box .search-icon {
  flex: 1 1;
  text-align: center;
}
.edit-mycollection .dom-h2 .search-option .search-box .search-input {
  flex: 4 1;
  margin-left: 9px;
  font-weight: normal;
}
.edit-mycollection .dom-h2 .search-option .search-button {
  flex: 1 1;
}
.edit-mycollection .edit-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50px;
}
.edit-mycollection .edit-row .w180 {
  width: 180px;
  height: 270px;
  border: 1px #ccc solid;
  cursor: pointer;
  background-color: #fff;
}
.edit-mycollection .edit-row .w180 img {
  width: 100%;
  height: 100%;
}
.edit-mycollection .edit-row .item {
  position: relative;
  margin: 10px;
  box-sizing: border-box;
}
.edit-mycollection .edit-row .btn-delete {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 2;
  background: #f44336;
  padding: 3px;
}
.edit-mycollection .edit-row .btn-delete:hover,
.edit-mycollection .edit-row .btn-delete:active {
  background: #f44336;
}
.edit-mycollection .edit-row .btn-choose {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 2;
  background: #007AFF;
  padding: 3px;
}
.edit-mycollection .edit-row .btn-choose:hover,
.edit-mycollection .edit-row .btn-choose:active {
  background: #007AFF;
}

html,
body {
  min-height: 100%;
  font-size: 12px;
}
.main-app {
  flex: 1 1;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.Connect-App--main-2 {
  flex: 1 1;
}
.listSelet {
  background: #2196f3;
}
.listSelet span {
  color: #ffff;
}

ul li {
  list-style: none;
}
ul {
  padding: 20px 0px;
}
ul li {
  border-bottom: 1px #ccc solid;
}
ul li .li-btn {
  width: 48px;
  margin-left: 10px;
}
ul li .tag {
  border-radius: 20px;
  background: #666;
  color: #fff;
  padding: 0 20px;
  margin: 0 20px;
  font-weight: normal;
  font-size: 16px;
}
ul li .num {
  font-weight: normal;
  font-size: 14px;
  color: #ccc;
}
ul li .date {
  color: #ccc;
}
ul li a:visited {
  color: #111;
}
ul li a:hover {
  color: #111;
}
ul li a:active {
  color: #111;
}
ul li a {
  color: #111;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.right-con {
  padding: 20px;
  overflow-y: scroll;
}
.notice-wrapper ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.password {
  width: 50%;
}
.lang ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
}
.type-dict {
  border-radius: 12px;
  background: #999;
  padding: 0 10px;
  margin: 0 10px;
  color: #fff;
  font-weight: normal;
}
.dict-page {
  font-weight: normal;
}
.width100 {
  width: 100%;
}
.p-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.p-content span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.information-ul li {
  cursor: pointer;
  padding-top: 15px;
}
.information-ul li:hover {
  background: rgba(0, 0, 0, 0.08);
}
.information-ul h4 {
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0.2em;
          margin-block-end: 0.2em;
}
.information-ul p {
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0.1em;
          margin-block-end: 0.1em;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

