.chatbox-con{
    display: flex;
    min-width: 800px;
    height: 400px;
    flex-direction: column;
    .date-time{    
        text-align: center;
        color: #a0a09d;
        line-height: 24px;
        margin: 13px;
    }
    .guest,.me {
        position: relative;
        border-radius: 5px;
        align-items: flex-start;
        margin: 20px 5px;
        word-break: break-all;
        padding: 10px 20px;
        max-width: 735px; 
        line-height: 18px;
        color: #000;
    }  
    .guest{
        background: #dcd8d8;
        align-self: flex-start;
        margin-left: 30px;
    }
    .me{
        background: #39c2ea;
        align-self: flex-end;
        margin-right: 30px;
    }
    .guest .avatar{ 
        position: absolute;
        left: -45px;
        bottom: -35px;
        /* width: 50px; */
        text-align: center;
    }
    .guest .avatar .avatar-icon{ 
        width: 40px;
        height: 40px;
        display: block;
        // float: left;
    }
    .guest .avatar span{ 
        color: #9e9393;
        width: 100px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        text-align: left;
        padding-right: 5px;
    }

    .guest .arrow:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: -14px;
        bottom:1px;
        border: 8px solid #dcd8d8;
        border-color:transparent #dcd8d8 transparent  transparent ;
    }

    .me .avatar{ 
        position: absolute;
        right: -45px;
        bottom: -35px;
        /* width: 50px; */
        text-align: center;
    }
    .me .avatar .avatar-icon{ 
        width: 40px;
        height: 40px;
        display: block;
        position: absolute;
        right: 0px;
        top: -44px;
        // float: right;
    }
    .me .avatar span{ 
        color: #9e9393;
        width: 100px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        text-align: right;
        padding-right: 5px;
    }
    .me .arrow:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: -14px;
        bottom:1px;
        border: 8px solid #39c2ea;
        border-color:transparent transparent transparent #39c2ea;
    }
}
.input-con{
    width: 100%;
    flex:1;
    display: flex;
}
.input{
    flex:1;
    display: flex;
    border:none;
    resize: unset;
    font-family: none;
}
.input:focus{
    border: none;
    box-shadow: none;
    outline:none;
}