.one-line,.two-line{  
    width:180px;
    position: relative; 
    line-height: 20px; 
    max-height: 40px;
    overflow: hidden;
    margin:0;
    padding:0 5px;
}
.two-line::after{
    content: "..."; 
    position: absolute; 
    bottom: 0; 
    right: 5px; 
    padding-left: 5px;
    background: -webkit-linear-gradient(left, transparent, #fff 0%);
    background: -o-linear-gradient(right, transparent, #fff 0%);
    background: -moz-linear-gradient(right, transparent, #fff 0%);
    background: linear-gradient(to right, transparent, #fff 60%);
}
.edit-mycollection{
    width:101%;
    position: relative;
    .dom-h2{
        padding: 10px;
        padding-top: 13px;
        display: flex;
        justify-content: space-between;
        min-width: 100%;
        border-bottom: 1px solid #dfd7d7;
        position: fixed;
        z-index: 5;
        top: 50px;
        .h2-title{
            display: inline-block;
            line-height: 33px;
        }
        .search-option{
            display: flex;
            align-items: center;
            text-align: right;
            .search-box{
                flex: 1.5;
                display: flex;
                align-items: center;
                width: 250px;
                height: 33px;
                .search-icon{
                    flex:1;
                    text-align:center;
                }
                .search-input{
                    flex:4;
                    margin-left: 9px;
                    font-weight: normal;
                }
            }
            .search-button{
                flex:1;
            }
        }
    }
    .edit-row{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        padding: 10px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50px;
        .w180{
            width:180px;
            height: 270px;
            border:1px #ccc solid;
            cursor: pointer;
            background-color: #fff;
        }
        .w180 img{
            width: 100%;
            height: 100%;
        }
        .item{
            position: relative;
            margin:10px;
            box-sizing: border-box;
        }
        .btn-delete{
            position: absolute;
            right:-15px;
            top:-15px;
            z-index: 2;
            background:#f44336;
            padding: 3px;
            &:hover,&:active{
                background:#f44336;
            }
        }
        .btn-choose{
            position: absolute;
            right:-15px;
            top:-15px;
            z-index: 2;
            background:#007AFF;
            padding: 3px;
            &:hover,&:active{
                background:#007AFF;
            }
        }
    }
}