.mar5{
  margin:5px;
}
.pd10{
  padding: 10px;
}
.text-center{
  text-align: center;
}
.book-wrap{
  width:100%;
  .tool-bars{
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .center-part{
      font-size: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 59px;
      max-width: 20%;
    }
    .right-part{
      em{
        font-style: normal;
      }
        .option-box-inline{
          em{
            div:nth-child(2){
              display: inline-block;
            }
        }
      }
    }
  }
  #bookContainer{
    margin:0 auto;
    min-width: 100%;
    max-width: 85%;
    //height: 100%;
    padding:10px 0 50px;
    position: relative;
    z-index: 1;
    @media print{display:none}
  }
  #book{
    padding: 20px;
    width:90%;
    margin: 0px auto;
    display: flex;
    justify-content: space-around;
    .page_single{
      margin:0 auto;
      transform-origin:top left;
      border: 1px solid #dcdbdb;
    }
    .page_horizontal{
      width: 100%;
      display: flex;
      justify-content: space-around;
      transform-origin:top left;
      .react-pdf__Page{
        border: 1px solid #dcdbdb;
      }
    }
  }
  #bookView{
    // padding: 20px;
    width:86%;
    margin: 0px auto;
    display: block;
    border: 1px solid #efeded;
  }
}
#textLayer{
  ::selection{
    background: #2196f3
  }
}
.fontsize-wrap{
  padding:40px;
  overflow: hidden;
}
#colorSelect{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111;
}
.print-wrap{
  position: relative;
  display: inline-block;
  z-index: 9;
  font-style:normal;
}
.search-list{
  .search-item{
    padding:10px 0;
    display: flex;
    border-bottom: 1px #ccc solid;
    justify-content: space-between;
  }
}
.star-wrap{
  padding:20px 60px;
  span{
    cursor: pointer;
  }
}
.slider-wraper{
  width: 2px;
  height: 100%;
  position: absolute;
  top: -300px;
  z-index: 1;
  .rigth-slider{
    &>div{
      overflow: visible;
    }
  }
}

.page-btn{
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top:10%;
  width: 4%;
  height: 55%;
  z-index: 2;
  display: flex;
  align-items: center;
  background: #eee;
}
.page-btn:hover{
  background: #ddd;
}
.page-btn svg{
  width:100%;
  height:60px;
}
.prev-btn{
  left:12px;
} 
.next-btn{
  right:12px;
}
#loadingDiv {
  .MuiDialog-paperWidthSm-255{
    background: transparent!important;
    box-shadow: none!important;
  }
}
.serchselect{
  margin: -1px;
  padding: 1px;
  background-color: yellow;
  border-radius: 4px;
}
@media print{
  @page {
    size: portrait;
    margin: 2mm;
  }
  #pdfPrint div{
    background: white;
    page-break-after: always;
  }
}

.option-box{
  position: relative;
}
.option{
  position: absolute;
  right: 10px;
  z-index: 9;
  ul{
    overflow-y: auto;
    max-height: 255px;
  }
}
.fab-wrap{
  display: flex;
  position: fixed;
  bottom: 50px;
  right: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .option-btn{
    width: 36px;
    height: 36px;
    margin: 5px 0px;
  }
}
.iconSmall{
        width:22px;
        height:22px;
    }
.iconBig{
        width:35px;
        height:35px;
    }