html,body{
    min-height: 100%;
    font-size: 12px;
}
.main-app{
    flex:1;
    display: flex;
    height: 100%;
    flex-direction: column;
}
.Connect-App--main-2{
    flex:1;
}
.listSelet{
    background: #2196f3;
    span{
        color: #ffff;
    }
}