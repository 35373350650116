
ul li{
    list-style:none;
}
ul{
    padding:20px 0px;
    li{
        border-bottom: 1px #ccc solid;
        .li-btn{
            width: 48px;
            // height: 48px;
            margin-left:10px;
        }
        .tag{
            border-radius: 20px;
            background: #666;
            color:#fff;
            padding:0 20px;
            margin:0 20px;
            font-weight: normal;
            font-size: 16px;
        }
        .num{
            font-weight: normal;
            font-size: 14px;
            color:#ccc;
        }
        .date{
            color:#ccc;
        }
    }
    li a:visited{
        color:#111;
    }
    li a:hover{
        color:#111;
    }
    li a:active{
        color:#111;
    }
    li a{
        color:#111;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

}
.right-con{
    padding:20px;
    overflow-y: scroll;
}
.notice-wrapper{
    ul li{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.password{
    width:50%;
}
.lang ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding:10px 0;
}
.type-dict{
    border-radius: 12px;
    background:#999;
    padding:0 10px;
    margin:0 10px;
    color:#fff;
    font-weight: normal;
}
.dict-page{
    font-weight: normal;
}
.width100{
    width:100%;
}
.p-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.p-content span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.information-ul{
    li{
        cursor: pointer;
        padding-top: 15px;
    }
    li:hover{
        background:rgba(0, 0, 0, 0.08);
    }
    h4{
        margin-block-start: 0em;
        margin-block-end: 0.2em;
    }
    p{
        margin-block-start: 0em;
        margin-block-end: 0.1em;
    }
}