.flex{
    display: flex;    
}
@pink:#f44336;
@blue:#2196f3;
.triangle{
    width: 0;
    height: 0;
    display: inline-block;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: @pink transparent @pink @pink;
}
.wrap{
    flex-wrap: wrap;
}
.fullWidth{
    width:100%;
}
.marTB10{
    margin:5px 10px 5px 0;
    &>div{
        max-width: 20%;
        min-width: 180px;
    }
}
.mr10{
    margin-right: 10px;
}
.textLeft{
    text-align: left;
    & div{
        text-align: left;
    }
}
.stars{
    padding: 15px 0;
    span{
        display: flex;
        justify-content: flex-start;
    }
}
.twoLines{
    width:100%;
    word-break:break-all;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    overflow:hidden;
}
//phone
@media (max-width: 768px) {
    .xs-per100{
        width: 100%;
        margin:5px 10px 5px 0;
        box-sizing: border-box;
    }
    
}
//pad 
@media (min-width: 768px) {
    .sm-per33{
        width: 33.3%;
        margin:5px 10px 5px 0;
        box-sizing: border-box;
    }
    
}
//pc device
@media (min-width: 960px) {
    .md-per10 {
            width: 10%;
            min-width: 180px;
            margin:5px 10px 5px 0;
            box-sizing: border-box;
    }
}

.newbox,.updatebox{
    position: absolute;
    left:0;
    top:20px;
    z-index: 1;
    color:#fff;
    font-size:0;
    width:70px;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    // box-shadow: -1px -2px 3px 0px #eee;
    span{
        height: 20px;
        font-size:14px;
        width:50px;
        padding-left:10px;
        background: @pink;
        vertical-align: middle;
        display: inline-block;
    }
    i{
        height: 20px;
        vertical-align: middle;
        display: inline-block;
        
    }
}
.cross-newbox,.cross-updatebox{
    position: relative;  
    color:#fff;
    font-size:0;
    width:70px;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    // box-shadow: -1px -2px 3px 0px #eee;
    span{
        height: 20px;
        font-size:14px;
        width:50px;
        color:#fff;
        padding-left:10px;
        background: @pink;
        vertical-align: middle;
        display: inline-block;
    }
    i{
        height: 20px;
        vertical-align: middle;
        display: inline-block;
        
    }
}
.updatebox,.cross-updatebox{
    width:85px;
    span{
        width:65px;
        background: @blue;
    }
    i{
        border-color: @blue transparent @blue @blue;;
    }
}
.message{
    position: absolute;
    bottom:0;
    right:0px;
    z-index: 1;
}


.one-list{
    display: flex;
    flex-direction: column;
    margin:10px 20px 20px 0;
    .w150{
        width:150px;
        height: 208px;
        position: relative;
        border:1px #ccc solid;
        cursor:pointer;
        background-color: #fff;
    }
    .w150 img{
        width: 100%;
        height: 100%;
    }
    .w150-bottom{
        width: '150px';
        white-space: nowrap;
    }
}

.crossWrap{
    width:100%;
}
.crossPage{
    padding:10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .leftPart{
        display: flex;
        flex:1;
        justify-content: flex-start;
    }
    .leftPart .imgCon{
        width: 150px;
        height: 208px;
        cursor: pointer;
        background-color: #fff;
    }
    .leftPart .imgCon img{
        width:100%;
        height: 100%;
    }
    .leftPart .textCon{
        padding-left:20px;
        flex:1;
    }
    .rightPart{
        width:60px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.img-right{
    display: flex;
    flex-direction: column;
    height: 100%;
    p{
        margin:0;
    }
    .mb10{
        margin-bottom: 10px;
    }
}
.loading-div{
    top: 65px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1300;
    position: fixed;
    // background:rgba(0, 0, 0, 0.54);
    .loading{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        outline: none;
    }
}
.search-list .search-item{
    cursor: pointer;
}